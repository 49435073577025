<template>
  <div class="support">
    <h3>Support</h3>
    <p>Contact cocinaconloquetienesapp@gmail.com</p>
  </div>
</template>

<script>
export default {
  name: "Support",
};
</script>

<style scoped>
.support {
  font-size: 1.2rem;
  padding: 1rem;
  text-align: justify;
}
</style>
