<template>
  <div>
    <br />
    <div class="info">
      <div class="subtitle">
        Dinos qué tienes en la nevera y te decimos qué puedes cocinar.
      </div>
      <div class="subtitle">🍽 Rápido y fácil</div>
      <div class="subtitle">⬇️ Descarga la app</div>
    </div>

    <div class="stores">
      <div class="store">
        <a
          class="store-link"
          href="https://play.google.com/store/apps/details?id=com.cocinaconloquetienesapp.cocinaconloquetienes"
        >
          <img
            class="store-image"
            src="../../src/assets/playstore.png"
            alt="Logo de Play Store"
          />
          <div class="store-title">Play Store</div>
        </a>
      </div>
      <div class="store">
        <a
          class="store-link"
          href="https://apps.apple.com/es/app/cocina-con-lo-que-tienes/id1473141867"
        >
          <img
            class="store-image"
            src="../../src/assets/appstore.png"
            alt="Logo de App Store"
          />
          <div class="store-title">App Store</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.subtitle {
  font-size: 1.2rem;
  padding: 0.5rem;
}
.stores {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
}
.store {
  padding: 1rem;
  margin: 0 0.5rem;
}
.store a {
  color: #2c3e50;
  text-decoration: none;
}
.store-title {
  font-size: 1.3rem;
}
.store-image {
  max-width: 6rem;
}
</style>