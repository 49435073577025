<template>
  <div class="header">
    <router-link class="header-link" to="/">
      <img
        class="header-image"
        alt="Cocina con lo que tienes logo"
        src="../../src/assets/icon.png"
      />
      <span class="header-title">Cocina con lo que tienes</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped>
.header {
  background-color: #fff;
  height: 3rem;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 2px 0px #dedede;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
}
.header-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #2c3e50;
  text-decoration: none;
}
.header-title {
  font-weight: 500;
  font-size: 1.3rem;
  cursor: pointer;
}
.header-image {
  height: 100%;
  padding-right: 1rem;
  cursor: pointer;
}
</style>
