import { createRouter, createWebHashHistory } from 'vue-router';

import Home from "./components/Home.vue";
import Support from "./components/Support.vue";
import Privacy from "./components/Privacy.vue";
import Recipe from "./components/Recipe.vue";

const routes = [{
    path: '/',
    components: { Home },
}, {
    path: '/support',
    components: { Support },
}, {
    path: '/privacy',
    components: { Privacy },
}, {
    path: '/recipe/:image',
    components: { Recipe },
    props: true,
}];

export default createRouter({
    history: createWebHashHistory(),
    routes,
});