<template>
  <div class="recipe">
    {{ image }}
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
  name: "Recipe",
  props: {
    image: String,
  },
  setup (props) {
    useMeta({
      title: `CCLQT Title`,
      meta: [
        {
          property: "og:title",
          content: "Meta Title",
        },
        {
          property: "og:description",
          content: "Meta Description",
        },
        {
          property: "og:image",
          content: `https://cocinaconloquetienes-assets.s3.eu-west-1.amazonaws.com/recipes/${props.image}`,
        },
      ],
    });
  },
};
</script>

<style scoped>
</style>
