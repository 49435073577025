<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | Cocina con lo que tienes` : `Cocina con lo que tienes`
      }}</template>
    </metainfo>
    <Header />
    <div class="content">
      <router-view name="Home" />
      <router-view name="Support" />
      <router-view name="Privacy" />
      <router-view name="Recipe" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
  background-color: #f9f9f9;
  box-sizing: border-box;
}
.content {
  margin-top: 3rem;
}
</style>
