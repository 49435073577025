<template>
  <div class="footer">
    <div class="subtitle">
      ¿Quieres colaborar? Mándanos tus recetas a
      <a href="https://www.instagram.com/cocinaconloquetienesapp/"
        >@cocinaconloquetienesapp</a
      > o a <a href="mailto:cocinaconloquetienesapp@gmail.com">cocinaconloquetienesapp@gmail.com</a>
    </div>
    <router-link class="footer-option" to="/">Inicio</router-link>
    <span>•</span>
    <router-link class="footer-option" to="/support">Soporte</router-link>
    <span>•</span>
    <router-link class="footer-option" to="/privacy">Privacidad</router-link>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.subtitle {
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.subtitle a {
  color: #3054a7;
  text-decoration: none;
  font-weight: bold;
}
.footer-option {
  padding: 0 0.5rem;
  color: #2c3e50;
  text-decoration: none;
}
.footer-option:hover {
  text-decoration: underline;
}
</style>
